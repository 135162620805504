interface IBlogPaginationProps {
  total: number
  page: number
  perPage: number
  onChange?: React.Dispatch<React.SetStateAction<number>>
}
export const Pagination = ({ onChange, page, perPage, total }: IBlogPaginationProps) => {
  const length = Math.floor(total / perPage) + (total % perPage > 0 ? 1 : 0)
  return (
    <div className="blogs__pagination">
      {page > 0 && (
        <div
          className="blogs__pagination-item arrow-left"
          onClick={() => onChange((old) => old - 1)}
        >
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="21" y1="8" x2="4" y2="8" stroke="#9F9F9F" strokeWidth="2" />
            <path
              d="M13 1V1C10.0847 4.80963 5.75285 7.34985 1 8V8V8C5.75285 8.65016 10.0847 11.1904 13 15V15"
              stroke="#9F9F9F"
              strokeWidth="2"
            />
          </svg>
        </div>
      )}
      {Array.from({ length }).map((_, index) => (
        <div
          onClick={() => onChange(index)}
          className={`blogs__pagination-item arrow-right${index === page ? " active" : ""}`}
        >
          {index + 1}
        </div>
      ))}
      {page < length - 1 && (
        <div
          onClick={() => onChange((old) => old + 1)}
          className="blogs__pagination-item arrow-right"
        >
          <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="-8.28406e-08" y1="8" x2="17" y2="8" stroke="#9F9F9F" strokeWidth="2" />
            <path
              d="M8 15V15C10.9153 11.1904 15.2471 8.65015 20 8V8V8C15.2471 7.34984 10.9153 4.80963 8 0.999999V0.999999"
              stroke="#9F9F9F"
              strokeWidth="2"
            />
          </svg>
        </div>
      )}
    </div>
  )
}
