import React from "react"
import { useFormContext } from "react-hook-form"
import { CtrlPhone } from "@/Components/ctrl/Phone"
import { CtrlInput } from "@/Components/ctrl/Input"

export const Expense3DSPassword = () => {
  const { watch } = useFormContext()

  return (
    <>
      <label className="mb10">PIN Code</label>
      <CtrlInput
        isPinField
        type="password"
        name="password3ds"
        label="Create PIN Code"
        autoComplete="new-password"
        required
        refProps={{
          minLength: {
            value: 4,
            message: "Minimal length of PIN Code: 4",
          },
        }}
      />
      <div style={{ marginTop: 10 }} />
      <CtrlInput
        isPinField
        type="password"
        name="password3ds_confirm"
        label="Confirm PIN Code"
        autoComplete="new-password"
        required
        refProps={{
          validate: (value: string) => value === watch("password3ds") || `PIN Code didn't match`,
          minLength: {
            value: 4,
            message: "Minimal length of PIN Code: 4",
          },
        }}
      />
      <div style={{ marginTop: 10 }} />
      <div className="Form-InputBox Form-InputBox--Dark">
        <span className="Form-Input__Label">Mobile</span>
        <CtrlPhone name="phone" placeholder="Mobile" required />
      </div>
    </>
  )
}
