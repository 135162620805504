import { Image } from "@/Components/common/Image"
import { Currencies } from "@/types"
import { Rate } from "@usher/pe-client-front-end-lib"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"

const getRate = (rates: Rate[], currency: string) => rates.find((rate) => rate.symbol === currency)
const currencyNames = (curr: string) => {
  switch (curr.toUpperCase()) {
    case "BTC":
      return "Bitcoin"
    case "LTC":
      return "Litecoin"
    case "ETH":
      return "Ethereum"
    case "USDC-BEP20":
    case "USDC-ERC20":
      return "USDC"
    case "USDT":
    case "USDT-ERC20":
    case "USDT-TRC20":
      return "Tether"
    default:
      return curr
  }
}
interface IAssetLinkBoxProps {
  rates: Rate[]
  fiat?: string
  currency: Currencies
}

export const AssetLinkBox = ({ currency, rates, fiat = "EUR" }: IAssetLinkBoxProps) => {
  const rate = getRate(rates, currency.toUpperCase())
  return (
    <div
      className={`assets__link-box assets__link_${Number(rate?.change ?? 0) < 0 ? "down" : "grow"}`}
    >
      <div className="assets__link-row">
        <div className="assets__link-cur">
          <Image src={`${currencyNames(currency).toLowerCase()}-icon.svg`} alt="" />
          <div className="assets__link-cur-info">
            <span>{currencyNames(currency)}</span>
            <span>{currency.toUpperCase()}</span>
          </div>
        </div>
        <div className="assets__link-price">
          <NumberFormat
            value={rate?.rate ?? 0}
            displayType="text"
            thousandSeparator={true}
            suffix={` ${fiat}`}
          />
        </div>
      </div>
      <Link to="/exchange" className="assets__link">
        <Image src="arrow-link.svg" alt="" />
      </Link>
    </div>
  )
}
