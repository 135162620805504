import { Image } from "@/Components/common/Image"

export const HowWorks = () => {
  return (
    <section className="work">
      <div className="container">
        <div className="work__wrap">
          <h2 className="h2 work__title">How it works</h2>
          <ul className="work-list">
            <li className="work-list__item">
              <Image src="work-5.png" />
              <h4 className="work-list__title">Get wizarbit card</h4>
              <div className="work-list__text">
                The card can be mailed to you if you want. The only thing you should do is to verify
                your account and fill in the application.
              </div>
            </li>
            <li className="work-list__item">
              <Image src="work-6.png" alt="" />
              <h4 className="work-list__title">LOADING GOES NEXT</h4>
              <div className="work-list__text">
                Top up your card BTC, ETH, LTC, USDT, USDT-TRC20. The system will convert it to
                Euros so you can easily spend them at your convenience.
              </div>
            </li>
            <li className="work-list__item">
              <Image src="work-7.png" alt="" />
              <h4 className="work-list__title">NOW USE WHAT YOU HAVE GOT!</h4>
              <div className="work-list__text">
                Use your card as a regular ATM card for everyday needs, shopping and in the ATMs
                worldwide. No borders, no limits.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
