import { FormWrapper } from "@/Components/Form"
import { Checkbox } from "@/Components/Form/Checkbox"
import { Input } from "@/Components/Form/Input"
import { Msg } from "@/Components/Msg"
import { Stack } from "@/Layout/Stack"
import { VALID_PHONE, useExchangeStep2 } from "@usher/pe-client-front-end-lib"
import { useHistory } from "react-router-dom"
import {
  AgreeAgeCheck,
  AgreeRefundCheck,
  AgreeRiskCheck,
  AgreeTermCheck,
} from "../Auth/Register/Checkboxes"
import { Button } from "@/Components/Control/Button"
import { CountrySelect } from "@/NewComponents/CountrySelect"
import { useIntl } from "react-intl"

export const PersonalInfo = () => {
  const history = useHistory()
  const intl = useIntl()
  const { loading, formMethods, onSubmit } = useExchangeStep2({ history: useHistory() })
  return (
    <div className="personal_info">
      <FormWrapper methods={formMethods} noValidate onSubmit={onSubmit}>
        <h4 className="transaction__title">Add personal information</h4>
        <Stack gap={20}>
          <Input label="First name" required name="first_name" />
          <Input label="Last name" required name="last_name" />
        </Stack>
        <Stack gap={20}>
          <Input
            label="Phone number"
            required
            name="phone"
            rules={{ ...VALID_PHONE, minLength: undefined, maxLength: undefined }}
          />
          <CountrySelect
            name="nationality"
            required
            fullWidth
            label={intl.formatMessage({ id: "national" })}
          />
        </Stack>
        <div className="select_sex">
          <Checkbox name="sex" value="1" required label={<Msg id="male" />} type="radio" />
          <Checkbox name="sex" value="0" required label={<Msg id="female" />} type="radio" />
        </div>
        <div className="personal__box-title">
          <h4 className="transaction__title personal__title">Billing address</h4>
          <p className="transaction__subtitle">Must match your utility bill</p>
        </div>
        <Stack gap={20}>
          <CountrySelect
            name="country_code"
            required
            fullWidth
            label={intl.formatMessage({ id: "country" })}
          />
          <Input label="State" required name="state" />
        </Stack>
        <Stack gap={20}>
          <Input label="City" required name="city" />
          <Input label="Zip code" required name="zip" />
        </Stack>
        <Stack gap={20}>
          <Input label="Street address" required name="address" />
          <Input label="Apt/suit" required name="adress2" />
        </Stack>

        <div className="policy">
          <AgreeTermCheck />
          <AgreeRiskCheck />
          <AgreeRefundCheck />
          <AgreeAgeCheck />
        </div>

        <div className="exchange__actions">
          <Button loading={loading} variant="primary" type="submit">
            Exchange
          </Button>
          <Button variant="outlined" onClick={() => history.push("/")}>
            Cancel
          </Button>
        </div>
      </FormWrapper>
    </div>
  )
}
