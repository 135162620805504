import { CardsCreateHeader } from "../Header"
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom"
import { SelectPayment } from "./SelectPayment/SelectPayment"
import { CreateForm } from "./CreateForm"
import Instructions from "@/Pages/TopUpCard/Steps/Instructions"
import { CustomStepper } from "@/Components/CustomStepper"
import Status from "@/Pages/TopUpCard/Steps/Status"
const STEPS = [
  "Please choose what kind of card you want to create:",
  "Please complete the card order",
]
export const CardCreateExpense = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const isExpensePage = /expense\/?$/gi.test(pathname)
  return (
    <>
      <CardsCreateHeader subtitle="" description="" />
      <div style={{ marginBottom: 40 }}>
        <CustomStepper currentStep={isExpensePage ? 1 : 2} steps={STEPS} />
      </div>
      <Switch>
        <Route path={path} exact>
          <CreateForm />
        </Route>
        <Route path={`${path}/select-payment`}>
          <SelectPayment />
        </Route>
        <Route path={`${path}/instructions`}>
          <Instructions isCreateCard />
        </Route>
        <Route path={`${path}/status/:token`}>
          <Status isCreate />
        </Route>
      </Switch>
    </>
  )
}
