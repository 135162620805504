import { Layout } from "@/Layout"
import "./index.sass"
import { Link, Redirect, Route, Router, Switch, useHistory } from "react-router-dom"
import { STEPS, Stepper } from "./components/Stepper"
import { Suspense, lazy } from "react"
import { BackButton } from "@/Components/Button/BackButton"
import { useTopUpCard } from "@usher/pe-client-front-end-lib"
import { Image } from "@/Components/common/Image"

const ByCrypto = lazy(async () => await import("./Steps/ByCrypto/index"))
const Confirmation = lazy(async () => await import("./Steps/Confirmation"))
const Instruction = lazy(async () => await import("./Steps/Instructions"))
const Status = lazy(async () => await import("./Steps/Status"))
const TransactionHash = lazy(async () => await import("./Steps/TransactionHash"))

const DefaultView = ({ noLogo = false }: { noLogo?: boolean }) => {
  const {
    currentStep,
    isAuthorized,
    isInitialized,
    path,
    isFirstPage,
    isLastPage,
    isInstructionPage,
    isHashPage,
  } = useTopUpCard(STEPS)
  const history = useHistory()
  if (!isInitialized) return null
  if (!isAuthorized) return <Redirect to="/login" />
  return (
    <>
      {!noLogo && (
        <div className="Page_Wrapper__Logo">
          <Link to="/" className="Header__Logo">
            <Image src={`logo-dark.svg`} />
          </Link>
        </div>
      )}
      <Suspense fallback="Loading...">
        <div
          className="Page_Wrapper"
          style={noLogo ? { minHeight: "100vh", paddingBottom: 20 } : {}}
        >
          <div className="Container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="buy-steps__h2">Top Up Card</h2>
                <Stepper currentStep={currentStep} />
              </div>
            </div>
            <Switch>
              <Route
                exact
                path={path}
                render={() => <Redirect to={`${path}/${STEPS[0].path}`} />}
              />
              <Route exact path={`${path}/${STEPS[0].path}`} component={ByCrypto} />
              <Route path={`${path}/${STEPS[1].path}`} component={Confirmation} />
              <Route path={`${path}/${STEPS[2].path}/hash/:token`} component={TransactionHash} />
              <Route path={`${path}/${STEPS[2].path}`} component={Instruction} />
              <Route path={`${path}/${STEPS[3].path}/:token`} component={Status} />
            </Switch>
            {!isInstructionPage && (
              <div className="bottom-line-btn d-flex align-items-center">
                {!isLastPage && (
                  <BackButton
                    onClick={() =>
                      isHashPage ? history.replace("/top-up-card/by-crypto") : history.goBack()
                    }
                  />
                )}
                {!isFirstPage && !isLastPage && !isHashPage && (
                  <button
                    className="btn-reset btn-sq btn-long btn-red"
                    color="Blue"
                    onClick={() => history.push(STEPS[currentStep].path)}
                  >
                    NEXT
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </Suspense>
    </>
  )
}
export const TopUpCard = () => {
  return (
    <Layout withHeader={false} footerStyle={{ backgroundColor: "#F5FAFF" }}>
      <DefaultView />
    </Layout>
  )
}

export const WidgetView = () => {
  return <DefaultView noLogo />
}
