import { Link, useLocation, useRouteMatch } from "react-router-dom"
import "./index.sass"
import { Transactions } from "../../Transactions"
import { isWidgetPage } from "@/utils"

export const Exchange = () => {
  const path = useRouteMatch().path
  const { pathname } = useLocation()
  const isCardPage = /\/card\/?$/.test(pathname)
  return (
    <>
      <div className="main-content_content">
        <div className="main-content_left">
          <div className="div-h1">Transaction History</div>
          <div className="main-content_btn">
            <Link
              to="/client/exchange"
              className={`btn-reset btn-sq btn-long no-decorate ${!isCardPage ? "btn-blue" : ""}`}
            >
              Exchange
            </Link>
            <Link
              to={`${path}/card`}
              className={`btn-reset btn-sq no-decorate ${isCardPage ? "btn-blue" : ""}`}
            >
              CARD
            </Link>
          </div>
        </div>
        {/* <ExchangeTypeBuy />
        <ExchangeTypeSell /> */}
        <Transactions />
        <div style={{ marginTop: 50 }}>
          <Transactions type="Sell" />
        </div>
      </div>
      {/* <DashboardInfoBar /> */}
    </>
  )
}
export const ExchangeWidget = () => {
  return <Transactions type="Sell" />
}
