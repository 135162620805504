import { Layout } from "@/Layout"
import "./style.css"
import { Input } from "@/Components/Form/Input"
import { useForm } from "react-hook-form"
import { FormWrapper } from "@/Components/Form"
import { VALID_EMAIL, useAvailableCountries } from "@usher/pe-client-front-end-lib"
import { ArrowButton, Button } from "@/Components/Control/Button"
import { Header } from "./components/Header"
import { Tab } from "./components/Tab"
import "./style.sass"
import { TableItem } from "./components/TableItem"
import { useHistory } from "react-router-dom"

export const CoveredCountries = () => {
  const history = useHistory()
  const formMethods = useForm<{ email: string }>()
  const { onShowMore, regions, isShowMore, onRegionChange, regionId, countries } =
    useAvailableCountries()
  return (
    <Layout darkTheme>
      <section className="countries">
        <div className="container">
          <div className="countries__wrap">
            <Header />
            <div className="countries-form">
              <FormWrapper
                className="country_forma"
                methods={formMethods}
                onSubmit={({ email }) => history.push(`/register?email=${email}`)}
              >
                <div className="email_wrapper">
                  <Input
                    required
                    dark
                    name="email"
                    rules={VALID_EMAIL}
                    isEmail
                    label="Enter Your Email"
                  />
                </div>
                <Button type="submit">Sign Up</Button>
              </FormWrapper>
            </div>
            <Tab
              currentRegionId={regionId}
              regions={regions}
              onSelect={(regionId) => onRegionChange(regionId)}
            />
            <div className="country-table">
              <div className="table_head">
                <div className="country-table__country">Country</div>
                <div className="country-table__buy">Buy</div>
                <div className="country-table__sell">Sell</div>
                <div className="country-table__methods">Payment Methods</div>
                <div className="country-table__issuing">Debit cards issuing</div>
              </div>
              {countries.map((country) => (
                <TableItem key={country.code} item={country} />
              ))}
            </div>
            {!isShowMore && (
              <div className="countries__more">
                <ArrowButton title="Show More" to="" onClick={onShowMore} />
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}
