import { Button } from "@/Components/Control/Button"
import { useEffect, useMemo, useState } from "react"
import { AssetLinkBox } from "./AssetLinkBox"
import "./style.css"
import { useAvailableAssets } from "@usher/pe-client-front-end-lib"
import { Currencies } from "@/types"
import { useHistory } from "react-router-dom"

export const HomeAssets = () => {
  const [showAll, setShowAll] = useState(false)
  const history = useHistory()
  const { ratesFiat } = useAvailableAssets()
  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth > 1126 && showAll) setShowAll(false)
    }
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [showAll])
  const filteredRates = useMemo(
    () => ratesFiat.filter((rate) => rate.symbol !== "BCH"),
    [ratesFiat]
  )
  const isLess = window.innerWidth < 1120
  return (
    <section className="home_section assets">
      <div className="container">
        <div className="assets__box">
          <div className="assets__info">
            <div className="assets__top">
              <h2 className="h2 assets__h2">Various digital assets</h2>
              <p className="assets__describe">
                Wizarbit provides fast and secure fiat for crypto exchange online.
              </p>
              {/* <img src="img/assets-image.png" alt="" className="assets__image" /> */}
            </div>
          </div>
          <div className={`assets__info-middle assets__info-top`}>
            <AssetLinkBox rates={filteredRates} currency={Currencies.BTC} />
            <AssetLinkBox rates={filteredRates} currency={Currencies.ETH} />
            <AssetLinkBox rates={filteredRates} currency={Currencies.USDC_ERC20} />
          </div>
          {showAll && (
            <>
              <div className={`assets__info-middle${showAll && isLess ? " showAll" : ""}`}>
                <AssetLinkBox rates={filteredRates} currency={Currencies.USDT_TRC20} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.USDT_ERC20} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.LTC} />
              </div>
              <div className={`assets__info-middle${showAll && isLess ? " showAll" : ""}`}>
                <AssetLinkBox rates={filteredRates} currency={Currencies.USDC_BEP20} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.DASH} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.ICP} />
              </div>
            </>
          )}
          <div
            className="assets__info-bottom"
            style={{
              justifyContent: showAll && !isLess ? "flex-end" : "unset",
              marginRight: showAll && !isLess ? -7 : 0,
            }}
          >
            <div className="assets__info-bottom-info">
              <p className="assets__info-bottom-text">
                Using Wizarbit you can be certain that the highest standards of encrypting are
                employed to protect your data.
              </p>
              <Button
                onClick={() => {
                  setShowAll(!showAll)
                }}
                minWidth={242}
              >
                {`${showAll ? "Hide" : "See"} all assets`}
              </Button>
            </div>
            <div className={`assets__info-bottom-cards${showAll && isLess ? " showAll" : ""}`}>
              {!showAll ? (
                <>
                  <AssetLinkBox rates={filteredRates} currency={Currencies.USDT_TRC20} />
                  <AssetLinkBox rates={filteredRates} currency={Currencies.DASH} />
                </>
              ) : (
                <>
                  <AssetLinkBox rates={filteredRates} currency={Currencies.BNB} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
