import { WidgetView } from "@/Pages/TopUpCard"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { CardsPageWidget } from "../Cards"
import { WidgetLayout } from "@/Layout/WidgetLayout"
import { WidgetMain } from "../RightSiderbar/WidgetView"
import { useClientApp, useStoreClient } from "@usher/pe-client-front-end-lib"
import { useEffect, useLayoutEffect } from "react"
import { setCookieConsentBottom } from "@/utils"
import { NotFound } from "@/Pages/NotFound"
import { OrdersPage, OrdersPageWidget } from "../Orders"

export const Widget = () => {
  const path = useRouteMatch().path
  const fetchCards = useStoreClient((state) => state.fetchClientCards)
  const fetchAtiveCards = useStoreClient((state) => state.fetchActiveCards)
  useEffect(() => {
    const fetchCallback = () => {
      fetchCards(true)
      fetchAtiveCards(true)
    }
    window.addEventListener("focus", fetchCallback)
    return () => {
      window.removeEventListener("focus", fetchCallback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (window.innerWidth < 768) setCookieConsentBottom(75)
    return () => setCookieConsentBottom(0)
  }, [])

  const { isInitialized } = useClientApp(true)
  if (!isInitialized) return null

  return (
    <WidgetLayout>
      <div className="Dashboard" style={{ width: "100% !important" }}>
        <div className="Dashboard__Content" style={{ width: "100%" }}>
          <Switch>
            <Route exact path={`${path}/`} component={WidgetMain} />
            <Route path={`${path}/top-up`} component={WidgetView} />
            <Route path={`${path}/cards`} component={CardsPageWidget} />
            <Route path={`${path}/exchange`} component={OrdersPageWidget} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </div>
    </WidgetLayout>
  )
}
