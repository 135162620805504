import { ReactNode, useState } from "react"
import "./style.sass"
import { Image } from "@/Components/common/Image"

interface IHintWrapperProps {
  content: ReactNode
}

export const HintWrapper = ({ content }: IHintWrapperProps) => {
  return (
    <div className="custom__tooltip">
      <Image src="label-icon.svg" data-tooltip-id="custom__tooltip" />
      <div className="custom__tooltip-wrapper">
        <div className="custom__tooltip-header">
          <div />
          <svg
            width="54"
            height="27"
            viewBox="0 0 54 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 10.5306H0.5V11.0306V26V26.5H1H53H53.5V26V17C53.5 15.2138 52.5479 13.5964 51.0077 12.4255C49.468 11.2547 47.3403 10.5306 44.99 10.5306C44.2732 10.5306 43.5881 10.2341 43.0965 9.71095C43.0964 9.71092 43.0964 9.71089 43.0964 9.71087L36.2244 2.39412L35.86 2.73642L36.2244 2.39411C34.22 0.260061 30.82 0.296883 28.8622 2.4738L28.8622 2.47382L23.1072 8.8738L23.1072 8.87381C22.1591 9.9283 20.8072 10.5306 19.389 10.5306H1Z"
              fill="#F6F2FD"
              stroke="#DECEFB"
            />
          </svg>
        </div>
        <div className="custom__tooltip-content">{content}</div>
      </div>
    </div>
  )
}
