type Props = {
  subtitle: string;
  description?: string;
};

export const CardsCreateHeader = ({ subtitle, description }: Props) => {
  return (
    <div className="main-form-cards_header">
      <div className="main-form-cards_title1">Order New Card</div>
      {subtitle !== "" && (
        <div className="main-form-cards_subtitle1">{subtitle}</div>
      )}
      {description && (
        <div className="main-form-cards_description">{description}</div>
      )}
    </div>
  );
};
