import { Image } from "@/Components/common/Image"
import { Layout } from "@/Layout"
import { BSelect } from "@/NewComponents/BSelect"
import { Payments } from "../Home/Payments"
import { AssetLinkBox } from "../Home/HomeAssets/AssetLinkBox"
import "./style.css"
import { Currencies } from "@/types"
import { useAvailableAssets } from "@usher/pe-client-front-end-lib"
import { useMemo } from "react"
import { Container } from "@/Layout/Container"

const FIAT_OPTION = { label: "EUR", value: "EUR" }
export const Rates = () => {
  const { ratesFiat } = useAvailableAssets()
  const filteredRates = useMemo(
    () => ratesFiat.filter((rate) => rate.symbol !== "BCH"),
    [ratesFiat]
  )
  return (
    <Layout>
      <main className="rates-main">
        <section className="assets">
          <Container>
            <div className="assets__box">
              <div className="assets__top">
                <h2 className="h2 assets__h2">Cryptocurrency rates</h2>
                <h2 className="h2 assets__h2-mob">How to buy bitcoin?</h2>
              </div>
              <div className="assets__info">
                <div className="assets-form">
                  <BSelect
                    buttonStyle={{ minWidth: 100 }}
                    options={[FIAT_OPTION]}
                    value={FIAT_OPTION}
                  />
                </div>
                <div className="assets__block">
                  <AssetLinkBox currency={Currencies.BTC} rates={filteredRates} />
                  <AssetLinkBox currency={Currencies.ETH} rates={filteredRates} />
                </div>
              </div>
              <div className="assets__info-middle">
                <AssetLinkBox currency={Currencies.LTC} rates={filteredRates} />
                <AssetLinkBox currency={Currencies.USDT_ERC20} rates={filteredRates} />
                <AssetLinkBox currency={Currencies.USDT_TRC20} rates={filteredRates} />
              </div>
              <div className={`assets__info-middle`}>
                <AssetLinkBox rates={filteredRates} currency={Currencies.USDC_ERC20} />

                <AssetLinkBox rates={filteredRates} currency={Currencies.USDC_BEP20} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.DASH} />
              </div>
              <div className={`assets__info-middle`}>
                <AssetLinkBox rates={filteredRates} currency={Currencies.ICP} />
                <AssetLinkBox rates={filteredRates} currency={Currencies.BNB} />
              </div>
            </div>
          </Container>
        </section>

        <section className="min">
          <div className="container">
            <div className="min__wrap">
              <h2 className="h2 min__title">The minimal possible commission</h2>
              <div className="min__box">
                <div className="min__box-top">
                  <ul className="min-list">
                    <li className="min-list__item">
                      <div className="min-list__box-img">
                        <Image src="min-1.png" />
                      </div>
                      <h3 className="min-list__title">Membership program</h3>
                      <p className="min-list__text">
                        Just as the registration is completed, you become a partner of the
                        Membership Program.
                      </p>
                    </li>
                    <li className="min-list__item">
                      <div className="min-list__box-img">
                        <Image src="min-2.png" />
                      </div>
                      <h3 className="min-list__title">ANYWHERE AND ANYTIME</h3>
                      <p className="min-list__text">
                        With no restrictions is accessible worldwide.
                      </p>
                    </li>
                    <li className="min-list__item">
                      <div className="min-list__box-img">
                        <Image src="min-3.png" />
                      </div>
                      <h3 className="min-list__title">100 PERCENT AUTOMATED</h3>
                      <p className="min-list__text">
                        Automatization and the newest technologies are for your convenience.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="min__box-bottom">
                  <ul className="min-list">
                    <li className="min-list__item min-list__item-bot">
                      <div className=" min-list__box-marg">
                        <Image src="min-4.png" />
                      </div>
                      <h3 className="min-list__title">DISCOUNTS</h3>
                      <p className="min-list__text min-list__text-bot">
                        In order to fully satisfy our clients in their needs.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Payments isLight />
      </main>
    </Layout>
  )
}
