import { useFormContext } from "react-hook-form"
import { CtrlInput } from "@/Components/ctrl/Input"
export const ExpensePinCode = () => {
  const { watch } = useFormContext()

  return (
    <div className="mb33">
      <label className="mb10">PIN code</label>
      <div className="physical_pin d-flex" style={{ gap: 20 }}>
        <CtrlInput
          isPinField
          type="password"
          name="pin"
          className="mb10"
          required
          label="Create PIN Code"
          style={{ marginBottom: 2 }}
          maxLength={4}
          refProps={{
            minLength: {
              value: 4,
              message: "Minimal length of PIN Code: 4",
            },
          }}
        />
        <CtrlInput
          isPinField
          type="password"
          name="pin_confirm"
          label="Confirm PIN code"
          className="mb10"
          required
          style={{ marginBottom: 2 }}
          maxLength={4}
          refProps={{
            validate: (value: string) => {
              if (value !== watch("pin")) return `PIN codes didn't match`
            },
            minLength: {
              value: 4,
              message: "Minimal length of PIN Code: 4",
            },
          }}
        />
      </div>
    </div>
  )
}
