import React, { InputHTMLAttributes } from "react"
import classnames from "classnames"
import { useFormContext } from "react-hook-form"
import { VALID_EMAIL } from "@usher/pe-client-front-end-lib"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  refProps?: object
  required?: boolean
  theme?: string
  label?: string
  placeholder?: string
  emptyLabel?: boolean
  withSelect?: boolean
  isPinField?: boolean
}

export const CtrlInput = ({
  type = "text",
  name,
  theme = "Dark",
  label = "",
  placeholder = "",
  emptyLabel = false,
  withSelect = false,
  refProps = {},
  required = false,
  children,
  isPinField = false,
  ...rest
}: InputProps) => {
  const { register, errors } = useFormContext()

  if (required) refProps = { ...refProps, required: "This field is required." }
  if (type === "email") refProps = { ...refProps, ...VALID_EMAIL }

  // if (errors[name]) className += ' is-invalid'
  const inputProps = register({
    ...refProps,
    ...(isPinField
      ? {
          validate: (value: string) => {
            if (/(\d)\1{3}/.test(value))
              return "Your PIN code contains identical digits. For better security, use a more varied PIN."
            //@ts-ignore
            if (refProps.validate != null) return refProps?.validate?.(value)
          },
        }
      : {}),
  })

  return (
    <div className={classnames("Form-InputBox", "Order-Card-PIN-Input", `Form-InputBox--${theme}`)}>
      {label && placeholder.length < 2 && <span className="Form-Input__Label">{label}</span>}
      {emptyLabel && <span className="Form-Input__Label">&nbsp;</span>}
      <input
        className={classnames("Form-Input", {
          [`Form-Input--${theme}`]: theme,
          [`Form-Input--WithSelect`]: withSelect,
        })}
        placeholder={placeholder}
        type={type}
        name={name}
        ref={inputProps}
        maxLength={isPinField ? 4 : undefined}
        onInput={(e) => {
          if (isPinField) {
            e.preventDefault()
            e.currentTarget.value = e.currentTarget.value.replace(/\D/g, "")
          }
        }}
        {...rest}
      />
      {children}
      {errors[name] && <div className="invalid-feedback">{errors[name].message}</div>}
    </div>
  )
}
