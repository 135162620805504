import { Image } from "@/Components/common/Image"
import { ArrowButton } from "@/Components/Control/Button"
import { scrollTo } from "@/utils"

export const Cards = () => {
  return (
    <section className="cards">
      <div className="container">
        <div className="cards__wrap">
          <div className="cards__item">
            <Image src="cards-black.png" />
            <div className="cards__box">
              <h3 className="cards__title">Physical</h3>
              <div className="cards__slogan">
                Our cards are built for every transaction you need. Whether it's swiping at stores
                or getting cash from ATMs, they're always there for you. Easy to use and accepted
                everywhere, it's time to switch to our physical cards for a better way to pay.
              </div>
            </div>
            <ul className="cards-list">
              <li className="cards-list__item">1-7 business day delivery</li>
              <li className="cards-list__item">Online and in-store payments</li>
              <li className="cards-list__item">ATM withdrawals</li>
              <li className="cards-list__item">Contactless payments</li>
              <li className="cards-list__item">Both Chip and Magnetic stripe</li>
              <li className="cards-list__item">Payments with Apple Pay and Google Pay</li>
            </ul>
            <ArrowButton title="Get a card" to="#" onClick={() => scrollTo("sing-up")} />
          </div>
          <div className="cards__item">
            <Image src="cards-purpl.png" />
            <div className="cards__box">
              <h3 className="cards__title">Virtual</h3>
              <div className="cards__slogan">
                Say hello to easy digital shopping! Our virtual cards make online transactions a
                breeze, offering convenience and security like never before. Instantly generated and
                accessible from your smartphone or computer, they provide peace of mind with
                advanced security features.
              </div>
            </div>
            <ul className="cards-list">
              <li className="cards-list__item">Online and in-store payments</li>
              <li className="cards-list__item">ATM withdrawals</li>
              <li className="cards-list__item">Contactless payments</li>
              <li className="cards-list__item">Payments with Apple Pay and Google Pay</li>
            </ul>
            <ArrowButton title="Get a card" to="#" onClick={() => scrollTo("sing-up")} />
          </div>
        </div>
      </div>
    </section>
  )
}
