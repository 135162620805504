import { LoadingAnimation } from "../../components/LoadingAnimation"
import { IECurrency } from "./E-CurrencyTable"

export const getCoinIcon = (coinName: string) => {
  if (coinName.startsWith("USDC")) return "usdc"
  if (coinName.startsWith("USD")) return "usdt"
  return coinName.toLowerCase()
}
export const ECurrencyItem = ({
  onDeposit,
  item,
  loading,
}: {
  item: IECurrency
  onDeposit: () => void
  loading: boolean
}) => {
  const symbol = item.symbol
  return (
    <div className="table-octagon_item" style={{ gap: 16 }}>
      <div className="table-octagon_current">
        <img src={`/img/coins/${getCoinIcon(symbol)}.svg`} alt="" />
        {item.symbol}
      </div>
      <div className="table-octagon_feep">{item.fee}%</div>
      <div className="table-octagon_fee">
        {(parseFloat(item.spend) * (item.fee / 100)).toFixed(5)} {symbol}
      </div>
      <div className="table-octagon_completion">from 6 Blockchain Network Confirmations</div>
      <div className="table-octagon_spend">
        <span>{item.spend}</span> {symbol}
      </div>
      <div className="table-octagon_btn">
        <button className={`btn-reset deposit-btn btn-sq btn-long btn-red `} onClick={onDeposit}>
          {loading ? <LoadingAnimation text={""} size={24} color="white" /> : "DEPOSIT"}
        </button>
      </div>
    </div>
  )
}
