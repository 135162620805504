import { useTransactionInfo } from "@usher/pe-client-front-end-lib"
import { getStatusColor } from "./TableItem"
import { useEffect } from "react"

interface ITransactionInfoProps {
  title: string
  transactionId: string
  isAction?: boolean
  show: boolean
  onView: (state?: boolean) => void
}
export const TransactionInfo = ({
  show,
  onView,
  title,
  transactionId,
  isAction,
}: ITransactionInfoProps) => {
  const { fetch, loading, data } = useTransactionInfo(transactionId)
  useEffect(() => {
    if (show) fetch(transactionId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, transactionId])
  return (
    <div
      style={!isAction ? { width: "20%" } : {}}
      className={`table-orders_item-view open-view-hover ${show ? "active" : ""}`}
    >
      <div className="btn-view-hover button-view-hover" onClick={() => onView(true)} />{" "}
      <div className={`view-hover`} onClick={(e) => e.stopPropagation()}>
        <div className="view-hover_close" onClick={() => onView()}></div>
        {loading ? (
          <div className="view-hover_tilte">Loading...</div>
        ) : data == null ? (
          <div className="view-hover_tilte" style={{ color: "red" }}>
            Cannot load info
          </div>
        ) : (
          <>
            <div className="view-hover_tilte">{title}</div>
            <div className="view-hover_body">
              <div className="view-hover_item">
                <div className="view-hover_lavel">Transaction ID:</div>
                <div className="view-hover_value">{data.id}</div>
              </div>
              <div className="view-hover_item">
                <div className="view-hover_lavel">Card number:</div>
                <div className="view-hover_value">{data.maskedCardNumber}</div>
              </div>
              <div className="view-hover_item view-hover_item--short">
                <div className="view-hover_lavel">Amount:</div>
                <div className="view-hover_value">
                  <span style={{ marginRight: 4 }}>{Number(data.amount).toFixed(2)}</span>
                  {data.currency}
                </div>
              </div>
              {/* <div className="view-hover_item view-hover_item--short">
                  <div className="view-hover_lavel">Fee:</div>
                  <div className="view-hover_value">
                    {Number(data.fee).toFixed(2)} {"  "} {data.currency}
                  </div>
                </div> */}
              <div className="view-hover_item">
                <div className="view-hover_lavel">Source:</div>
                <div className="view-hover_value">{data.source ?? ""}</div>
              </div>
              <div className="view-hover_item">
                <div className="view-hover_lavel">Status:</div>
                <div className={`view-hover_value ${getStatusColor(data.status)}`}>
                  {data.status}
                </div>
              </div>
              <div className="view-hover_item">
                <div className="view-hover_lavel">Note:</div>
                <div className="view-hover_value">
                  <span className="grey">{data.info ?? ""}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
