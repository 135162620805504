import React from "react"
import classNames from "classnames"
import { TransactionsFilter } from "../Filter"

export const TransactionsTable = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  page,
  type = "Buy",
  month_year,
  onFilter,
}) => {
  return (
    <div
      className="Box"
      style={{ paddingTop: "0", ...(onFilter != null ? { flexDirection: "column" } : {}) }}
    >
      {onFilter != null && (
        <div style={{ paddingTop: 20 }}>
          <TransactionsFilter widgetView onFilter={onFilter} type={type} />
        </div>
      )}
      <div className="Transactions__Table">
        <div className="table-orders_header" style={{ borderBottom: "1px solid #f0f5f9" }}>
          <div className="table-orders_title">
            <strong>Type Transaction:</strong> {type} Crypto
          </div>
          <div className="table-orders_date">{month_year}</div>
        </div>
        <div className="Table" {...getTableProps()} style={{ marginTop: 20 }}>
          <div className="Table__Col-Group">
            <div className="Table__Col" style={{ width: "8%" }} />
            <div className="Table__Col" style={{ width: "12%" }} />
            <div className="Table__Col" style={{ width: "13%" }} />
            <div className="Table__Col" style={{ width: "32%" }} />
            {type === "Sell" && <div className="Table__Col" style={{ width: "32%" }} />}
            <div className="Table__Col" style={{ width: "12%" }} />
            <div className="Table__Col" style={{ width: "9%" }} />
            <div className="Table__Col" style={{ width: "17%" }} />
          </div>
          <div className="Table__Head Transactions__Table-Head">
            {headerGroups.map((headerGroup) => (
              <div className="Table__Row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <div className="Table__Cell" {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="Table__Body" {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row)
              return (
                <React.Fragment key={key}>
                  <div className="Table__Row" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <div
                          {...cell.getCellProps([
                            {
                              className: classNames(
                                "Table__Cell Table__Cell--Grey",
                                cell.column.className
                              ),
                            },
                          ])}
                        >
                          {cell.render("Cell")}
                        </div>
                      )
                    })}
                  </div>
                  <div className="Table__Separator" />
                </React.Fragment>
              )
            })}
          </div>
        </div>
        {page?.length === 0 && (
          <p
            style={{ padding: "16px 0 24px", textAlign: "center", display: "block", width: "100%" }}
          >
            No transactions found.
          </p>
        )}
      </div>
    </div>
  )
}
