import { useCardFreeze } from "@usher/pe-client-front-end-lib"
import React from "react"
import { toast } from "react-toastify"
import { Image } from "@/Components/common/Image"
import { Modal } from "@/Components/Layout/Modal"
import { closeModal, openModal } from "@/Helpers/ModalHelper"

const ID = "freeze"

export const CardActionFreeze = () => {
  const { fetchAction, isBlocked, loading, cardNumber, cannotUnlock } = useCardFreeze({
    onSuccess: () => closeModal(ID),
  })

  return (
    <>
      <button
        className="btn-modal"
        onClick={() => {
          if (isBlocked && cannotUnlock) toast.error("A single card for one type can be active")
          else openModal(ID)
        }}
      >
        <Image src="freeze.svg" />
        {isBlocked ? "Unfreeze" : "Freeze"}
      </button>
      <Modal id={ID} title={`Are you sure you want to ${isBlocked ? "un" : ""}freeze the card?`}>
        <p>
          After confirmation, the card {cardNumber} will be {isBlocked ? "un" : ""}frozen.
        </p>
        {!isBlocked && (
          <button
            className="btn-reset btn-sq btn-long btn-red"
            onClick={() => fetchAction("lock")}
            disabled={loading}
          >
            Freeze
          </button>
        )}
        {isBlocked && (
          <button
            className="btn-reset btn-sq btn-long btn-green"
            onClick={() => fetchAction("unlock")}
            disabled={loading}
          >
            Unfreeze
          </button>
        )}
      </Modal>
    </>
  )
}
