import { CountryRegion } from "@usher/pe-client-front-end-lib/dist/types/CommonTypes"

interface ITabProps {
  regions: CountryRegion[]
  currentRegionId: number
  onSelect: (regionId: number) => void
}
export const Tab = ({ onSelect, regions, currentRegionId }: ITabProps) => {
  const mappedRegions = [{ id: 0, name: "All Regions" }, ...regions]
  return (
    <div className="countries__tabs">
      <ul className="countries__nav">
        {mappedRegions.map((region) => (
          <li key={region.id} onClick={() => onSelect(region.id)} className="countries__item">
            <span className={currentRegionId === region.id ? "active" : ""}>{region.name}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}
