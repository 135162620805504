import plus from "@/Images/cards_images/plus.svg"
import { SidebarAccountCards } from "./AccountCards"
import { Link } from "react-router-dom"
import { useActiveCards } from "@usher/pe-client-front-end-lib"

export const WidgetMain = () => {
  const { activeCards, loading, isCardActive, isAllExist } = useActiveCards()
  const balance = Number(activeCards?.[0]?.balance ?? "0")
  return (
    <>
      {isCardActive && (
        <div className="account-sidebar_cards">
          <div
            className="table-orders_title"
            style={{ marginBottom: 8, display: "flex", fontSize: 14 }}
          >
            Card Balance:&nbsp;&nbsp;
            <div className={balance < 0 ? "text-danger" : balance > 0 ? "text-success" : ""}>
              {` ${balance.toFixed(2)}  EUR`}
            </div>
          </div>
          {!loading && !isAllExist && (
            <Link className="account-sidebar_cards-add" to="/client/cards/order-card/expense">
              <img src={plus} alt="" />
            </Link>
          )}
          <SidebarAccountCards widgetView cards={activeCards} fetching={loading} />
        </div>
      )}
    </>
  )
}
