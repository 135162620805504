import React from "react"
import { FormProvider } from "react-hook-form"
import { Modal } from "@/Components/Layout/Modal"
import { closeModal } from "@/Helpers/ModalHelper"
import { useCardName } from "@usher/pe-client-front-end-lib"
import { CtrlInput } from "@/Components/ctrl/Input"
import { useSetCardNameMutation } from "@/gql/types"

const ID = "change-name"

export const CardActionName = () => {
  const { name, loading, onSubmit, formMethods, isClosed } = useCardName({
    mutation: useSetCardNameMutation,
    onSuccess: () => closeModal(ID),
  })

  if (isClosed) return <>{name}</>

  return (
    <div style={{ gap: 8, display: "flex", alignItems: "center" }}>
      {name}
      <div className="btn-change" data-bs-target={`#${ID}`} data-bs-toggle="modal" />
      <Modal id={ID} title="Change card name">
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <CtrlInput name="name" placeholder="Card name" className="mb10" required />
            <input
              type="submit"
              className="btn-reset btn-sq btn-long btn-blue"
              value="Save"
              disabled={loading}
            />
          </form>
        </FormProvider>
      </Modal>
    </div>
  )
}
