import { FormWrapper } from "@/Components/Form"
import { VALID_EMAIL, useExchangeStep1 } from "@usher/pe-client-front-end-lib"
import { useHistory } from "react-router-dom"
import { PaymentTypeSelect } from "./components/PaymentTypeSelect"
import { useMemo } from "react"
import { Stack } from "@/Layout/Stack"
import { Input } from "@/Components/Form/Input"
import {
  AgreeAgeCheck,
  AgreeRefundCheck,
  AgreeRiskCheck,
  AgreeTermCheck,
} from "../Auth/Register/Checkboxes"
import { CtrlReCaptcha } from "@/Components/ctrl/ReCaptcha"
import { Button } from "@/Components/Control/Button"
import { WalletAddressInput } from "./components/WalletAddressInput"
import { ExchangeVerifyModal } from "./components/ExchangeVerifyModal"
import { ExchangeAmount } from "./components/ExchangeAmount"

export const TransactionDetails = () => {
  const history = useHistory()
  // const intl = useIntl()
  const {
    formMethods,
    isAuthorized,
    isShowVerifyEmail,
    paymentTypes,
    onSubmit,
    onEmailVerified,
    onEmailVerifyHide,
    captchaRef,
    onCaptchaChange,
    step1Values,
    selectFiat,
    selectCrypto,
    hasWallet,
    onSelectPayment,
  } = useExchangeStep1({ history })

  const filteredPaymentTypes = useMemo(
    () =>
      [
        ...paymentTypes.slice(0, 3),
        paymentTypes.find((payment_type) => payment_type.name === "volt"),
      ].filter(Boolean),
    [paymentTypes]
  )
  const { paymentType } = step1Values
  return (
    <div className="transaction_details">
      <FormWrapper methods={formMethods} noValidate onSubmit={onSubmit}>
        <h4 className="transaction__title">Select payment method</h4>
        <PaymentTypeSelect
          onSelect={onSelectPayment}
          paymentTypes={filteredPaymentTypes}
          selectedPaymentType={paymentType}
        />
        <ExchangeAmount fiatSelect={selectFiat} cryptoSelect={selectCrypto} />
        {!isAuthorized && (
          <Stack>
            <Input required rules={VALID_EMAIL} label="E-mail" isEmail type="email" name="email" />
          </Stack>
        )}
        <WalletAddressInput hasWallet={hasWallet} />
        <div className="policy">
          <AgreeTermCheck />
          <AgreeRiskCheck />
          <AgreeRefundCheck />
          <AgreeAgeCheck />
        </div>

        {!isAuthorized && (
          <div className="FirstStep__Recaptcha">
            <CtrlReCaptcha refObj={captchaRef as never} onChange={onCaptchaChange} />
          </div>
        )}
        <div className="exchange__actions">
          <Button type="submit" variant="primary">
            Exchange
          </Button>
          <Button variant="outlined" onClick={() => history.push("/")}>
            Cancel
          </Button>
        </div>
      </FormWrapper>
      {isShowVerifyEmail && (
        <ExchangeVerifyModal onHide={onEmailVerifyHide} onVerified={onEmailVerified} />
      )}
    </div>
  )
}
