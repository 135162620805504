import { Image } from "@/Components/common/Image"
import "./style.css"

export const HowToBuy = () => {
  return (
    <section className="home_section buy">
      <div className="container">
        <div className="buy__row">
          <div className="buy__text">
            <h2 className="h2 by__h2">how to buy bitcoin?</h2>
            <ul className="buy__list">
              <li>
                <Image src="by-1.png" alt="" />
                <div className="buy__list-box">
                  <h3 className="buy__list-title">Sign Up</h3>
                  <p className="buy__list-describe">
                    Provide a valid e-mail, protect it with a strong password, set up your wallet
                    and you&apos;re good to go!
                  </p>
                </div>
              </li>
              <li>
                <Image src="by-2.png" alt="" />
                <div className="buy__list-box by__list-box--two">
                  <h3 className="buy__list-title">VERIFY</h3>
                  <p className="buy__list-describe">
                    Purchasing cryptocurrency without KYC (Know Your Customer) is impossible today.
                    With us it&apos;s simple. Be ready to provide us with your ID & personal
                    information.
                  </p>
                </div>
              </li>
              <li>
                <Image src="by-3.png" alt="" />
                <div className="buy__list-box">
                  <h3 className="buy__list-title">BUY CRYPTO</h3>
                  <p className="buy__list-describe">Buy crypto with credit or debit card</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
