import React from "react"
import { useFormContext } from "react-hook-form"
import { CtrlInput } from "@/Components/ctrl/Input"

export const Expense3DSPassword = () => {
  const { watch } = useFormContext()

  return (
    <div className="change-password-form">
      <label className="">PIN Code</label>
      <div style={{ marginBottom: 20 }}>
        <CtrlInput
          isPinField
          type="password"
          name="password3ds"
          placeholder="Create PIN Code"
          autoComplete="new-password"
          required
          refProps={{
            minLength: {
              value: 4,
              message: "Minimal length of PIN Code: 4",
            },
          }}
        />
      </div>

      <div style={{ marginBottom: 20 }}>
        <CtrlInput
          isPinField
          type="password"
          name="password3ds_confirm"
          placeholder="Confirm PIN Code"
          autoComplete="new-password"
          required
          refProps={{
            validate: (value: string) => value === watch("password3ds") || `PIN Code didn't match`,
            minLength: {
              value: 4,
              message: "Minimal length of PIN Code: 4",
            },
          }}
        />
      </div>
    </div>
  )
}
