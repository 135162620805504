import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { CtrlPhone } from "@/Components/ctrl/Phone"
import { CtrlInput } from "@/Components/ctrl/Input"
import { selectUser, useStoreClient } from "@usher/pe-client-front-end-lib"

export const ExpenseDeliveryAddress = () => {
  const user = useStoreClient(selectUser)
  const { register, watch } = useFormContext()

  const useCompany = watch("use_company")

  useEffect(() => {
    if (useCompany) return
    // @ts-ignore
    $("#country")?.countrySelect({
      excludeCountries: ["US"],
    })
  }, [useCompany])
  return (
    <>
      <label className="mb10">Delivery</label>
      <div className={`${useCompany ? "grey-form mb40" : "white-form"}`}>
        <input
          type="checkbox"
          name="use_company"
          id="use_company"
          className="input-checkbox"
          ref={register()}
        />
        <label htmlFor="use_company" className="label-checkbox mb15">
          Use data from the users profile
        </label>

        {useCompany && (
          <div className="info-form-block">
            <div className="info-form">
              <div className="info-form-label">First name</div>
              <div className="info-form-value">{user.first_name}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">Last name</div>
              <div className="info-form-value">{user.last_name}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">Address 1</div>
              <div className="info-form-value">{user.address ?? "N/A"}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">Country</div>
              <div className="info-form-value">{user.country_name}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">City</div>
              <div className="info-form-value">{user.city}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">Phone number</div>
              <div className="info-form-value">{user.phone}</div>
            </div>
            <div className="info-form">
              <div className="info-form-label">ZIP / Postal code</div>
              <div className="info-form-value">{user.zip}</div>
            </div>
          </div>
        )}
      </div>

      {!useCompany && (
        <div className="mb40">
          <CtrlInput name="firstName" label="First name" className="mb10" required />
          <CtrlInput name="lastName" label="Last name" className="mb10" required />
          <div className="Form-InputBox Form-InputBox--Dark">
            <span className="Form-Input__Label">Mobile</span>
            <CtrlPhone name="phone" placeholder="Mobile" required />
          </div>

          <CtrlInput name="countryCode" label="Country" id="country" required />
          <CtrlInput name="address1" label="Address" className="mb10" required />
          <CtrlInput name="city" label="City" className="mb10" required />
          <CtrlInput name="PostalCode" label="ZIP / Postal code" className="mb10" required />
        </div>
      )}
    </>
  )
}
