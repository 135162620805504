import { Stack } from "@/Layout/Stack"
import { ExchangeSelect } from "@/Components/Form/CustomSelect"
import { ExchangeSelector } from "@usher/pe-client-front-end-lib"
import { useFormContext } from "react-hook-form"

interface IExchangeAmountProps {
  fiatSelect: ExchangeSelector
  cryptoSelect: ExchangeSelector
}
export const ExchangeAmount = ({ cryptoSelect, fiatSelect }: IExchangeAmountProps) => {
  const { errors } = useFormContext()
  const errFiat = errors["fiatAmount"]
  const errCrypto = errors["cryptoAmount"]
  return (
    <Stack gap={20} direction="column">
      <ExchangeSelect
        error={errFiat != null}
        lightTheme
        selector={fiatSelect}
        label="Amount to Spend"
      />
      <ExchangeSelect
        error={errCrypto != null}
        lightTheme
        selector={cryptoSelect}
        label="Coins to Receive"
      />
    </Stack>
  )
}
