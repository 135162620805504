export const isMainPage = () => /^\/(en|lt)(\/?)$/.test(window.location.pathname)
export const isWidgetPage = () => window.location.pathname.includes("/client/widgets/")
export function GetLink(to: string, text: string) {
  return (
    <a href={`/legal/${to}`} target="_blank" rel="noreferrer">
      {text}
    </a>
  )
}

export const setCookieConsentBottom = (bottom: number) => {
  setTimeout(() => {
    const cookiContainer = document.querySelector(".CookieConsent") as HTMLDivElement
    if (cookiContainer != null) cookiContainer.style.bottom = `${bottom}px`
  }, 100)
}
export const scrollTo: (idName: string) => void = (idName) =>
  document.getElementById(idName)?.scrollIntoView({ behavior: "smooth" })
export const makeOption = (val: string[]) => val.map((value) => ({ label: value, value }))
