import { useMemo, useState } from "react"
import "./style.sass"
const FAQ_PRICES_TABLE_ITEMS: { title: string; value: string }[] = [
  { title: "Top up balance", value: "from 4,5%" },
  { title: "Withdrawal fiat from card to crypto", value: "4,5% (minimum 2 EUR)" },
  { title: "New Virtual Card", value: "€20" },
  { title: "Monthly maintenance fee", value: "€15" },
  { title: "POS physical transaction", value: "€0,6" },
  { title: "E-commerce 3DS transaction", value: "€0,65" },
  { title: "ATM transaction in Europe", value: "€3,00 + 1,5%" },
  { title: "ATM transaction outside Europe", value: "€3,00 + 3%" },
  { title: "PIN number change", value: "€2" },
  { title: "Balance check in ATM", value: "€2,5" },
  { title: "ATM/POS decline", value: "€0,6" },
  { title: "FX/Currency conversion", value: "3%" },
  { title: "Chargeback request", value: "€40,00" },
]
export const Prices = () => {
  const [activePage, setActivePage] = useState("virtual")
  const tableItem = useMemo(() => {
    const tableItems = [...FAQ_PRICES_TABLE_ITEMS]
    //
    if (activePage === "virtual") return FAQ_PRICES_TABLE_ITEMS
    else {
      tableItems[3].value = "€0"
      tableItems[2] = { title: "Physical Card + Delivery", value: "€40" }
      tableItems.splice(3, 0, { title: "Physical Card Reissue + Delivery", value: "€10" })
      return tableItems
    }
  }, [activePage])
  return (
    <div className="faq-prices">
      <div className="faq-prices__tab">
        <span
          className={`faq-prices__tab-item${activePage === "virtual" ? " active" : ""}`}
          onClick={() => setActivePage("virtual")}
        >
          <strong>Virtual</strong>
        </span>
        <span
          className={`faq-prices__tab-item${activePage === "physical" ? " active" : ""}`}
          onClick={() => setActivePage("physical")}
        >
          <strong>Physical</strong>
        </span>
      </div>
      <div className="faq-prices__table">
        <div className="faq-prices__table-item">
          <div />
          <div>EUR</div>
        </div>
        {tableItem.map((item) => (
          <div className="faq-prices__table-item">
            <div>{item.title}</div>
            <div>{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
