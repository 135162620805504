import { ExchangeBlock } from "../HeroScreen/ExchangeBlock"
import "./style.css"

interface IPaymentsProps {
  isLight?: boolean
}

export const Payments = ({ isLight }: IPaymentsProps) => {
  return (
    <section className={`payment${isLight ? " light-paymentt" : ""}`}>
      <div className="container">
        <h2 className="h2 payment__h2">
          buy bitcoins right now <span>using any payment</span> method
        </h2>
        <span className="h2 h2-mob">
          buy bitcoins <span>right now</span> using any
          <span>payment</span> method
        </span>
        <div className="payment__form">
          <form action="#">
            <ExchangeBlock isLight={isLight} />
          </form>
        </div>
      </div>
    </section>
  )
}
