import { useIntl } from "react-intl"
import "./style.css"
import {
  components,
  selectFetchPrices,
  useExchangeCartTotals,
  useStoreExchange,
} from "@usher/pe-client-front-end-lib"
import { HintWrapper } from "../HintWrapper"
import { HowCalculated } from "@/Components/HowCalculated"

interface IPaymentDetailsProps {
  fiatAmount: string
  fiatCurrency: string
  cryptoAmount: string
  cryptoCurrency: string
  isLastStep: boolean
}
export const PaymentDetails = ({
  cryptoAmount,
  cryptoCurrency,
  fiatAmount,
  fiatCurrency,
  isLastStep,
}: IPaymentDetailsProps) => {
  const intl = useIntl()
  const fetchPrices = useStoreExchange(selectFetchPrices)
  const { wallet, fee, rateAmount } = useExchangeCartTotals()
  const ftAmount = parseFloat(fiatAmount) || 0
  const feeAmount = (ftAmount / 100) * fee

  return (
    <div className="details">
      <h4 className="details__title">Payment Details</h4>
      <div className="details-form">
        <div className="details-form__box">
          <div className="details-form__numbs">
            <span>{parseFloat(fiatAmount || "0").toFixed(2)}</span>
            <span>{fiatCurrency}</span>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <path
              d="M14.0622 8.9089C14.0622 10.2213 13.2747 10.3151 13.1247 10.3151H1.59874L4.07831 7.83551L3.4174 7.16992L0.136312 10.451C-0.0454372 10.6338 -0.0454372 10.9291 0.136312 11.1119L3.4174 14.393L4.07831 13.7321L1.59874 11.2525H13.1247C13.7716 11.2525 14.9996 10.7838 14.9996 8.9089V7.50272H14.0622V8.9089Z"
              fill="black"
            ></path>
            <path
              d="M0.937454 6.09622C0.937454 4.78378 1.72492 4.69004 1.87491 4.69004H13.4009L10.9213 7.1696L11.5822 7.83051L14.8633 4.54942C15.0451 4.36658 15.0451 4.07134 14.8633 3.88851L11.5822 0.607422L10.9167 1.27301L13.4009 3.75258H1.87491C1.22807 3.75258 0 4.22131 0 6.09622L0 7.5024H0.937454V6.09622Z"
              fill="black"
            ></path>
          </svg>
          <div className="details-form__numbs">
            <span>{parseFloat(cryptoAmount || "0").toFixed(5)}</span>
            <span>{cryptoCurrency}</span>
          </div>
        </div>
        <div className="details-form__info">
          <components.Timer
            count_down={15}
            onUpdate={fetchPrices}
            wrapWithTag=""
            text={intl.formatMessage({ id: "recalculate" })}
          />
          {/* <HintWrapper content="Quvondiqov Allayor"/> */}
          <HowCalculated text={intl.formatMessage({ id: "p7" })} />
        </div>
      </div>
      <ul className="details-list">
        <li>
          <p>Exchange Rate:</p>
          <span>
            {rateAmount.toFixed(2)} {fiatCurrency}
          </span>
        </li>
        <li>
          <p>Processing Fee:</p>
          <span>
            {feeAmount.toFixed(2)} {fiatCurrency}
          </span>
        </li>
        <li>
          <p>Total Spent:</p>
          <span>
            {(ftAmount + feeAmount).toFixed(2)} {fiatCurrency}
          </span>
        </li>
        <li>
          <p>Total:</p>
          <span>
            {parseFloat(cryptoAmount || "0").toFixed(5)} {cryptoCurrency}
          </span>
        </li>
        {wallet != null ||
          ((wallet?.length ?? 0) > 0 && (
            <li>
              <p>Your Wallet:</p>
              <span>{wallet}</span>
            </li>
          ))}
      </ul>
      <div className="details__bottom" style={{ marginTop: isLastStep ? 156 : "auto" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.35294 0C9.78949 0 11.7647 1.97521 11.7647 4.41176V7.35294H13.2353C14.0475 7.35294 14.7059 8.01135 14.7059 8.82353V16.1765C14.7059 16.9887 14.0475 17.6471 13.2353 17.6471H1.47059C0.658405 17.6471 0 16.9887 0 16.1765V8.82353C0 8.01135 0.658405 7.35294 1.47059 7.35294H2.94118V4.41176C2.94118 1.97521 4.91639 0 7.35294 0ZM7.35294 10.2941C6.54076 10.2941 5.88235 10.9525 5.88235 11.7647C5.88235 12.2767 6.14395 12.7275 6.54077 12.9909L6.69567 13.0806L5.88235 14.7059H8.82353L8.01171 13.0798C8.49313 12.8382 8.82353 12.3401 8.82353 11.7647C8.82353 10.9525 8.16512 10.2941 7.35294 10.2941ZM7.35294 2.94118C6.54076 2.94118 5.88235 3.59958 5.88235 4.41176V7.35294H8.82353V4.41176C8.82353 3.59958 8.16512 2.94118 7.35294 2.94118Z"
            fill="#5405D6"
          ></path>
        </svg>
        SSL SECURE
      </div>
    </div>
  )
}
