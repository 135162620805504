import { useCardNumberAndCvv } from "@usher/pe-client-front-end-lib"

export const CardDetailsSensitive = () => {
  const { cardCvv, cardNumber, cardClosed, panToggled, cvvToggled, toggleCvv, togglePan } =
    useCardNumberAndCvv()

  return (
    <div className="block-one-card_details">
      <div className="block-one-card_info-title">Sensitive data</div>
      <div className="block-one-card_info-item">
        <div className="block-one-card_info-label">Card number</div>
        <div className="block-one-card_info-value">
          {cardNumber}
          {!cardClosed && (
            <div
              className={`button-view-details ${panToggled ? "no-view2" : ""}`}
              onClick={() => togglePan()}
            />
          )}
        </div>
      </div>
      <div className="block-one-card_info-item">
        <div className="block-one-card_info-label">CVV</div>
        <div className="block-one-card_info-value">
          {cardCvv}
          {!cardClosed && (
            <div
              className={`button-view-details ${cvvToggled ? "no-view2" : ""}`}
              onClick={() => toggleCvv()}
            />
          )}
        </div>
      </div>
    </div>
  )
}
