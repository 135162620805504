import "./index.sass";
import React from "react";
import Select from "react-select";
import classnames from "classnames";

export default class FormSelect extends React.Component {
  render() {
    const { options, type, theme, value, icon, handleChange, size, ...rest } =
      this.props;

    return (
      <Select
        className={classnames("Form-Select", {
          [`Form-Select--${type}`]: type,
          [`Form-Select--${theme}`]: theme,
          [`Form-Select--${size}`]: size,
        })}
        isSearchable={false}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {e.icon && (
              <img style={{ marginRight: 10 }} src={e.icon} alt={e.label} />
            )}
            {e.label}
            <span>{e.text}</span>
          </div>
        )}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999, width: 150 }),
        }}
        value={value}
        classNamePrefix="Form-Select-Simple"
        onChange={(e) => handleChange(e, rest.name)}
        options={options || []}
        {...rest}
      />
    );
  }
}
