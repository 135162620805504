import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle"
import { ToastContainer } from "react-toastify"
import { CookieConsent } from "react-cookie-consent"
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom"
import { setConfigUrls, useApp, useLang } from "@usher/pe-client-front-end-lib"
import { Home } from "@/Pages/Home"
import { Card } from "@/Pages/CardNew"
import { Auth } from "@/Pages/Auth"
import { Exchange } from "@/Pages/Exchange"
import { Faq } from "@/Pages/Faq"
import { Rates } from "@/Pages/RatesNew"
import { AboutUs } from "@/Pages/AboutUsNew"
import ScrollToTop from "@/Helpers/ScrollToTop"
import { ContactUs } from "@/Pages/ContactUsNew"
import { Limits } from "@/Pages/LimitsNew"
import { CoveredCountries } from "@/Pages/CoveredCountries"
import "react-toastify/dist/ReactToastify.css"
import { NotFound } from "@/Pages/NotFound"
import { Security } from "@/Pages/Security"
import "./index.css"
import { LANGUAGES } from "@/constants/LangConstants"
import { IntlProvider } from "react-intl"
// import { WizarbitTerms } from "./Pages/WizarbitTerms"
import { Msg } from "./Components/Msg"
import { LoadingPage } from "./Pages/LoadingPage"
import { UI } from "./Pages/UI"
import { Legal } from "./Pages/Legal"
import { Suspense, lazy, useEffect } from "react"
import { ReferalProgram } from "./Pages/ReferalProgram"
import TagManager from "react-gtm-module"
import { LANDING_TAG_ID, PROMO_TAG_ID, WIZARBIT_TAG_ID } from "./constants"
import { noop } from "jquery"
import { Blogs } from "./Pages/Blogs"
import { BlogContent } from "./Pages/Blogs/BlogContent"
import { Widget } from "./Pages/Dashboard/Widget"

const AvoidFraud = lazy(async () => await import("@/Pages/AvoidFraudNew"))

setConfigUrls({
  PAY: process.env.REACT_APP_PATH_PAY,
  SECURE: process.env.REACT_APP_PATH_SECURE,
})
export const App = () => {
  useApp()

  useEffect(() => {
    const isProd = process.env.NODE_ENV === "production"
    if (isProd) {
      const location = window.location
      const isPromo = location.host.startsWith("promo.")
      const isLanding = location.pathname.includes("landing-animated")
      let gtmId = WIZARBIT_TAG_ID
      if (isPromo) gtmId = PROMO_TAG_ID
      else if (isLanding) gtmId = LANDING_TAG_ID
      TagManager.initialize({ gtmId })
      //@ts-ignore
      window.gtag = (...args) => window.dataLayer.push(args)
      return
    }
    window.gtag = () => noop
  }, [])

  const { locale, messages, basename } = useLang({ languages: LANGUAGES })
  if (locale === "") return <LoadingPage />
  return (
    <IntlProvider locale={locale} messages={messages}>
      <ToastContainer autoClose={4000} hideProgressBar />
      <CookieConsent
        buttonClasses="Button Button--Blue Button--Mini"
        buttonStyle={{ margin: 15 }}
        disableButtonStyles
      >
        <Msg id="cookie" />
      </CookieConsent>
      <BrowserRouter basename={basename}>
        <Suspense fallback="Loading">
          <ScrollToTop />
          <div id="RightSidebar" className="Right__Sidebar"></div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/(login|register|recovery|new_password)" component={Auth} />
            <Route path="/card" component={Card} />
            <Route path="/exchange" component={Exchange} />
            <Route path="/faq" component={Faq} />
            <Route path="/blog/view/:blog_id" component={BlogContent} />
            <Route path="/blog" component={Blogs} />
            <Route path="/avoid-fraud" component={AvoidFraud} />
            <Route path="/rates" component={Rates} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/affiliate-program" component={ReferalProgram} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/rates-limits" component={Limits} />
            <Route path="/covered-countries" component={CoveredCountries} />
            <Route path="/client/widgets" component={Widget} />
            <Route path="/secure" component={Security} />
            <Route path="/legal" component={Legal} />
            <Route path="/ui" component={UI} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </IntlProvider>
  )
}
