import { ICardTransactionItem } from "@usher/pe-client-front-end-lib"
import { TableItem } from "./TableItem"
import Button from "@/Components/Button"
import { DownloadIcon } from "@/Images/Icons"
import { TransactionsFilter } from "../../Transactions/Filter"

export const CardTable = ({
  data,
  onDownloadClick,
  downloadLoading,
  setFilter,
}: {
  data: ICardTransactionItem[]
  onDownloadClick?: VoidFunction
  downloadLoading?: boolean
  setFilter: any
}) => {
  return (
    <div className="Transactions">
      <div className="table-orders" style={{ overflow: "visible" }}>
        {setFilter != null && (
          <div style={{ paddingTop: 20 }}>
            <TransactionsFilter
              widgetView
              compact
              type="Card"
              onFilter={setFilter}
              withoutExchangeTo
            />
          </div>
        )}
        <div className="table-orders_header">
          <div className="table-orders_title">
            <strong>Type Transaction:</strong> Card
          </div>

          <Button
            icon={<DownloadIcon />}
            color="Blue"
            size="Medium"
            type="submit"
            style={{ marginLeft: "auto" }}
            onClick={onDownloadClick}
          >
            {downloadLoading ? "Loading..." : "Download Statement "}
          </Button>
        </div>
        <div className="table-orders_body">
          <>
            <div className="table-orders_item table-orders_item-title">
              <div className="table-orders_item-number">ID#</div>
              <div className="table-orders_item-logo open-view-hover" style={{ width: "20%" }}>
                Transaction
              </div>
              <div className="table-orders_item-date">Date</div>
              <div className="table-orders_item-from-card">Amount</div>
              <div className="table-orders_item-bank">Card</div>
              {/* <div className="table-orders_item-to-card">Fee</div> */}
              <div className="table-orders_item-status">Status</div>
              <div className="table-orders_item-view open-view-hover">Action</div>
            </div>
            {data?.map((card, index) => (
              <TableItem card={card} key={index} />
            ))}
          </>
        </div>
      </div>
    </div>
  )
}
