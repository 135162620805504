import React, { useEffect, useRef } from "react"
import IntlTelInput from "react-intl-tel-input"
import { Controller, useFormContext } from "react-hook-form"
import "react-intl-tel-input/dist/main.css"
import { VALID_PHONE } from "@usher/pe-client-front-end-lib"

type Props = {
  name: string
  required?: boolean
  className?: string
  placeholder?: string
}

export const CtrlPhone = ({
  name,
  required = false,
  className = "",
  placeholder,
  ...rest
}: Props) => {
  const {
    control,
    errors,
    formState: { isSubmitting },
    setError,
    watch,
  } = useFormContext()
  const value = watch("phone")
  const ref = useRef<IntlTelInput>(null)
  let rules: any = VALID_PHONE
  if (required) rules = { ...rules, required: "This field is required." }
  if (errors[name]) className += " is-invalid"
  useEffect(() => {
    console.log(isSubmitting,value)
    if (isSubmitting && !ref.current.isValidNumber(value)) {
      setError("phone", { message: "Invalid phone number!" })
    }
  }, [setError, isSubmitting, ref, value])
  return (
    <div className="Order-Card-PIN-Input">
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ onChange, value, name }) => (
          <IntlTelInput
            ref={ref}
            fieldName={name}
            inputClassName={className}
            placeholder={placeholder}
            defaultValue="+44"
            value={value}
            onSelectFlag={(_, data) => onChange(`+${data.dialCode}`)}
            allowDropdown
            onPhoneNumberChange={(_, value) => onChange(value)}
            onPhoneNumberFocus={(_1, _2, data, _4, _5, event) => {
              setTimeout(() => {
                const end = event.target
                const len = data.dialCode.length + 1
                if (end.setSelectionRange) {
                  end.focus()
                  end.setSelectionRange(len, len)
                }
              }, 10)
            }}
            customPlaceholder={(placeholder, data) => data.dialCode}
            formatOnInit={false}
            defaultCountry="gb"
            {...rest}
          />
        )}
      />
      {errors[name]?.message && <div className="invalid-feedback">{errors[name].message}</div>}
    </div>
  )
}
