import { Msg } from "@/Components/Msg"
import "./style.sass"
import { useLocation, useRouteMatch } from "react-router-dom"
import { cfgExchange } from "@usher/pe-client-front-end-lib"
import { useEffect, useMemo, useRef } from "react"

export const Stepper = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const list = useRef<HTMLDivElement>(null)
  const filteredSteps = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(cfgExchange.STEPS).map(([_, obj], index) => ({
        index,
        ...obj,
      })),
    []
  )
  const currentRoute = filteredSteps.findIndex((route) => pathname === `${path}/${route.route}`)
  useEffect(() => {
    if (list.current != null) {
      const listRect = list.current.getBoundingClientRect()
      requestAnimationFrame(() => {
        let currElement = list.current.querySelector(`ul li:nth-child(${currentRoute + 1})`)
        const itemRect = currElement?.getBoundingClientRect()
        if (itemRect != null)
          setTimeout(() => {
            list.current.scrollTo({
              behavior: "smooth",
              left: itemRect.left - 20,
            })
          }, 500)
      })
    }
  }, [currentRoute, list])
  return (
    <div ref={list} className="transaction__box-list">
      <ul className="transaction-list">
        {filteredSteps.map((route, index) => (
          <li key={index} className="transaction-list__item">
            <div className={`transaction-list__title${index <= currentRoute ? " active" : ""}`}>
              <Msg id={`estep${index + 1}`} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
